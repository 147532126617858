import {Component, OnInit} from '@angular/core';
import {DisplayStudiesLoaderService} from "../display-studies-loader.service";
import {ActivatedRoute, Router} from "@angular/router";
import {ContextService} from "../../../context/context.service";
import {filter, tap} from "rxjs/operators";
import {combineLatest} from "rxjs";
import {DisplayStudy} from "../display-study";
import {StudyPurchaseDecisionUpdaterService} from "../study-detail/study-purchase-decision-updater.service";
import {NotificationService} from '../../../notification.service';

@Component({
    templateUrl: './specimen.component.html',
    styleUrls: ['./specimen.component.scss'],
    host: {'class': 'cell auto pageContainer grid-y'}
})
export class SpecimenComponent implements OnInit {
    public displayStudy: DisplayStudy;
    public category: string;
    public studyId: string;
    public state: 'LOADING' | 'DISPLAY_STUDY' | 'DISPLAY_SPECIMEN' = 'LOADING';

    constructor(
        private readonly displayStudyLoaderService: DisplayStudiesLoaderService,
        private readonly route: ActivatedRoute,
        private readonly contextService: ContextService,
        private readonly router: Router,
        private readonly studyPurchaseDecisionUpdaterService: StudyPurchaseDecisionUpdaterService,
        private readonly notificationService: NotificationService
    ) {
    }

    ngOnInit(): void {
        combineLatest(this.route.data, this.contextService.context)
            .pipe(
                filter(([currentAbstractStudyComponent, context]) => (
                    !!currentAbstractStudyComponent &&
                    !!context &&
                    !!context.currentSession &&
                    context.currentPeriod < context.currentSession.currentPeriod
                ))
            )
            .subscribe(([data, context]) => {
                this.category = data.categoryId;
                this.studyId = data.studyId;
                this.displayStudyLoaderService.loadDisplayStudy(
                    context,
                    this.category,
                    this.studyId
                ).subscribe(displayStudy => {
                        this.displayStudy = displayStudy;
                        if (displayStudy.available) {
                            this.state = 'DISPLAY_STUDY';
                        } else {
                            this.state = 'DISPLAY_SPECIMEN'
                        }
                    }
                )
            });
    }

    onPurchase() {
        this.studyPurchaseDecisionUpdaterService.purchaseStudy(
            this.contextService.context.getValue(),
            this.displayStudy
        ).pipe(
            tap(() => this.router.navigateByUrl('/session/decisions/studies/' + this.category + '/' + this.studyId))
        ).subscribe(() => {
                this.state = 'DISPLAY_STUDY';
                this.notificationService.decisionsSavedSuccessfully('toast.study.purchase.decision.saved.message')
            }
        );
    }

    onSpecimenRouterActivation(studyComponent: any) {
        studyComponent.isSpecimen = true;
    }
}
