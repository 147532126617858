import {Directive, Input} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn} from '@angular/forms';

@Directive({
    selector: '[kiwiMin]',
    providers: [{
        provide: NG_VALIDATORS,
        useExisting: KiwiMinValidator,
        multi: true
    }]
})
export class KiwiMinValidator implements Validator {
    private validator: ValidatorFn;

    @Input()
    set kiwiMin(min: number) {
        this.validator = KiwiMinValidator.buildValidator(min);
    }

    validate(control: AbstractControl): ValidationErrors | null {
            if (!this.validator) {
                return null;
            }

            return this.validator(control);
        }

    private static buildValidator(min: any) {
        return (control: AbstractControl): { [key: string]: any } => {
            if (min == undefined || min === '') {
                return null;
            }

            const value = control.value;

            if (!value) {
                return null;
            }

            return parseFloat(value) >= min ? null : {actualValue: value, requiredValue: min, min: true};
        };
    }
}
